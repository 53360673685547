import {create} from 'zustand'

export const useSummaryDailyReportingStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    timeZone: 'GMT+8',
    basedOn: 'transCompleted',
    dateTimeFrom: '',
    dateTimeTo: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
