import axios from 'axios'
import {usePendingWithdrawalStore} from '../Withdrawal-Pending-Page/Withdrawal-Pending-Store'
import {useWithdrawalStore} from '../Withdrawal-Page/Withdrawal_Store'
import {useBulkWithdrawalStore} from '../Withdrawal-Bulk-Withdrawal-Page/Withdrawal-Bulk-Withdrawal-Store'

export function RejectionModal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const API_URL = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let currentRejectedId = props.currentRejectedId
  let rejectionRemark = props.rejectionRemark
  let withdrawalFilterSubmit = props.withdrawalFilterSubmit

  //Zustand Store
  const setPendingGetData = usePendingWithdrawalStore((state: any) => state.setGetData)
  const setGetData = useWithdrawalStore((state: any) => state.setGetData)
  const setBulkGetData = useBulkWithdrawalStore((state: any) => state.setGetData)

  // Get the current URL
  var currentUrl = window.location.href

  function verifyCurrentUrl(currentUrl: string) {
    if (currentUrl.includes('withdrawal/list')) {
      setGetData(true)
    } else if (currentUrl.includes('/withdrawal/pending')) {
      setPendingGetData(true)
    } else if (currentUrl.includes('/withdrawal/bulk-approval')) {
      setBulkGetData(true)
    } else {
      console.log('Current URL does not match the specified patterns')
    }
  }

  function rejectWithdrawal() {
    axios
      .post(API_URL + '/cash/withdrawalrequest/reject', {
        request: {
          id: currentRejectedId,
          session: session,
          approvalRejectionRemark: rejectionRemark.current?.value,
        },
      })
      .then((response) => {
        withdrawalFilterSubmit.current.click()
        verifyCurrentUrl(currentUrl)
        alert(response.data.message)
      })
      .catch((error) => {
        verifyCurrentUrl(currentUrl)
        alert(error)
      })
  }
  return (
    <div
      className='modal fade'
      id='staticBackdrop_reject'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Do you want to reject withdrawal ID: {currentRejectedId}
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <label className='form-label fw-bolder text-dark fs-6'>Remark</label>
            <textarea
              name='rejectionRemark'
              ref={rejectionRemark}
              id='rejectionRemark'
              className='w-100'
              rows={5}
            ></textarea>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              className='btn btn-danger'
              data-bs-dismiss='modal'
              onClick={rejectWithdrawal}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
