import {create} from 'zustand'

export const useWithdrawalStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    searchCustomer: '',
    searchTransaction: '',
    merchantCd: '',
    walletCd: '',
    walletAccNo: '',
    withdrawalStatus: '',
    callbackStatus: '',
    dateTimeFrom: '',
    dateTimeTo: '',
    timeZone: 'GMT+8',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
