import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {ManualMatching} from './components/manual_matching'

const TransactionManagementPage_View: React.FC = () => {
  const navigate = useNavigate()
  const smsIdRef = React.useRef<any>()
  let location = useLocation()
  let transData: any = location.state

  const initialValues = {
    smsId: String(transData.smsId),
    receivedDt: String(transData.receivedDt).replace('T', ' ').replace(/\.\d+/, ''),
    completedDT:
      String(transData.completedDT) &&
      String(transData.completedDT).replace('T', ' ').replace(/\.\d+/, ''),
    merchant:
      String(transData.merchantCd) == ''
        ? ''
        : String(transData.merchantName) + ' - ' + String(transData.merchantCd),
    merchantName: String(transData.merchantName),
    merchantCd: String(transData.merchantCd),
    customerCd: String(transData.customerCd),
    walletCd: String(transData.walletCd),
    amount: String(transData.amount),
    transType: String(transData.transType),
    transSts: String(transData.transSts),
    callbackStatus: String(transData.callbackStatus),
    txnFee: String(transData.txnFee),
    txnId: String(transData.txnId),
    submittedTxnId: String(transData.submittedTxnId),
    senderReceiver: String(transData.senderReceiver),
    srcWalletAcc: String(transData.srcWalletAccNo),
    agentName: String(transData.agentName),
    walletAccNo: String(transData.walletAccNo),
    depositReqId: String(transData.depositReqId),
    smsBody: String(transData.smsBody),
    matchStatus: String(transData.matchStatus),
    remark: '',
    submission: '',
  }

  const addUserSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group SMS ID */}
          <div className='fv-row mb-8'>
            {' '}
            <label className='form-label fw-bolder text-dark fs-6'>SMS ID</label>
            <div className='d-flex'>
              <input
                placeholder={formik.values.smsId == '' ? 'EMPTY' : 'Enter SMS ID'}
                ref={smsIdRef}
                disabled={true}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('smsId')}
                className={clsx(
                  'form-control bg-grey',
                  {
                    'is-invalid': formik.touched.smsId && formik.errors.smsId,
                  },
                  {
                    'is-valid': formik.touched.smsId && !formik.errors.smsId,
                  }
                )}
              />
              {formik.touched.smsId && formik.errors.smsId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.smsId}</span>
                  </div>
                </div>
              )}
              <button
                type='button'
                className='btn btn-icon btn-primary ms-5'
                onClick={() => window.open('/sms/list' + '/' + smsIdRef.current.value, '_blank')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-chevron-double-right'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z'
                  />
                  <path
                    fill-rule='evenodd'
                    d='M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z'
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* end::Form group */}
          {/* begin::Form group Received DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Received DateTime</label>
            <input
              placeholder='Enter Received DateTime'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('receivedDt')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.receivedDt && formik.errors.receivedDt,
                },
                {
                  'is-valid': formik.touched.receivedDt && !formik.errors.receivedDt,
                }
              )}
            />
            {formik.touched.receivedDt && formik.errors.receivedDt && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.receivedDt}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Completed DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Completed DateTime</label>
            <input
              placeholder='EMPTY'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('completedDT')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.receivedDt && formik.errors.receivedDt,
                },
                {
                  'is-valid': formik.touched.receivedDt && !formik.errors.receivedDt,
                }
              )}
            />
            {formik.touched.receivedDt && formik.errors.receivedDt && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.receivedDt}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Merchant</label>
            <input
              placeholder={formik.values.merchant == '' ? 'EMPTY' : 'Enter Merchant'}
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('merchant')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.merchant && formik.errors.merchant,
                },
                {
                  'is-valid': formik.touched.merchant && !formik.errors.merchant,
                }
              )}
            />
            {formik.touched.merchant && formik.errors.merchant && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchant}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Customer Code</label>
            <input
              placeholder={formik.values.customerCd == '' ? 'EMPTY' : 'Enter Customer Code'}
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
            <input
              placeholder='Enter Wallet Code'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
            />
            {formik.touched.walletCd && formik.errors.walletCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Amount</label>
            <input
              placeholder='Enter Amount'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Transaction Type */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Transaction Type</label>
            <input
              placeholder='Enter Transaction Type'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('transType')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.transType && formik.errors.transType,
                },
                {
                  'is-valid': formik.touched.transType && !formik.errors.transType,
                }
              )}
            />
            {formik.touched.transType && formik.errors.transType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.transType}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Transaction Status */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Transaction Status</label>
            <input
              placeholder='Enter Transaction Status'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('transSts')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.transSts && formik.errors.transSts,
                },
                {
                  'is-valid': formik.touched.transSts && !formik.errors.transSts,
                }
              )}
            />
            {formik.touched.transSts && formik.errors.transSts && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.transSts}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Callback Status */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Callback Status</label>
            <input
              placeholder={formik.values.callbackStatus == '' ? 'EMPTY' : 'Enter Callback Status'}
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('callbackStatus')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.callbackStatus && formik.errors.callbackStatus,
                },
                {
                  'is-valid': formik.touched.callbackStatus && !formik.errors.callbackStatus,
                }
              )}
            />
            {formik.touched.callbackStatus && formik.errors.callbackStatus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.callbackStatus}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Transaction Fee */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Transaction Fee</label>
            <input
              placeholder='Enter Transaction Fee'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('txnFee')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.txnFee && formik.errors.txnFee,
                },
                {
                  'is-valid': formik.touched.txnFee && !formik.errors.txnFee,
                }
              )}
            />
            {formik.touched.txnFee && formik.errors.txnFee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txnFee}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Transaction ID */}
          {/* <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Transaction ID</label>
            <input
              placeholder='Enter Transaction ID'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('txnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.txnId && formik.errors.txnId,
                },
                {
                  'is-valid': formik.touched.txnId && !formik.errors.txnId,
                }
              )}
            />
            {formik.touched.txnId && formik.errors.txnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txnId}</span>
                </div>
              </div>
            )}
          </div> */}
          {/* end::Form group */}

          {/* begin::Form group Submitted Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Submitted Transaction ID</label>
            <input
              placeholder={
                formik.values.submittedTxnId == '' ? 'EMPTY' : 'Enter Submitted Transaction ID'
              }
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('submittedTxnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.submittedTxnId && formik.errors.submittedTxnId,
                },
                {
                  'is-valid': formik.touched.submittedTxnId && !formik.errors.submittedTxnId,
                }
              )}
            />
            {formik.touched.submittedTxnId && formik.errors.submittedTxnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.submittedTxnId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Sender Receiver */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Sender Receiver</label>
            <input
              placeholder='Enter Sender Receiver'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('senderReceiver')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.senderReceiver && formik.errors.senderReceiver,
                },
                {
                  'is-valid': formik.touched.senderReceiver && !formik.errors.senderReceiver,
                }
              )}
            />
            {formik.touched.senderReceiver && formik.errors.senderReceiver && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.senderReceiver}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Source Wallet Account */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Source Wallet Account</label>
            <input
              placeholder={
                formik.values.srcWalletAcc == '' ? 'EMPTY' : 'Enter Source Wallet Account'
              }
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('srcWalletAcc')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.srcWalletAcc && formik.errors.srcWalletAcc,
                },
                {
                  'is-valid': formik.touched.srcWalletAcc && !formik.errors.srcWalletAcc,
                }
              )}
            />
            {formik.touched.srcWalletAcc && formik.errors.srcWalletAcc && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.srcWalletAcc}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Agent Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Agent Name</label>
            <input
              placeholder={formik.values.agentName == '' ? 'EMPTY' : 'Enter Agent Name'}
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('agentName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.agentName && formik.errors.agentName,
                },
                {
                  'is-valid': formik.touched.agentName && !formik.errors.agentName,
                }
              )}
            />
            {formik.touched.agentName && formik.errors.agentName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.agentName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Wallet Account No */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account No</label>
            <input
              placeholder='Enter Wallet Account No'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Deposit Request ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Deposit Request ID</label>
            <input
              placeholder={formik.values.depositReqId == '' ? 'EMPTY' : 'Enter Deposit Request ID'}
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('depositReqId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.depositReqId && formik.errors.depositReqId,
                },
                {
                  'is-valid': formik.touched.depositReqId && !formik.errors.depositReqId,
                }
              )}
            />
            {formik.touched.depositReqId && formik.errors.depositReqId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.depositReqId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group SMS Content */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Content</label>
            <input
              placeholder='Enter SMS Content'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsBody')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsBody && formik.errors.smsBody,
                },
                {
                  'is-valid': formik.touched.smsBody && !formik.errors.smsBody,
                }
              )}
            />
            {formik.touched.smsBody && formik.errors.smsBody && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsBody}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Match Status */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Match Status</label>
            <input
              placeholder={formik.values.matchStatus == '' ? 'EMPTY' : 'Enter Match Status'}
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('matchStatus')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.matchStatus && formik.errors.matchStatus,
                },
                {
                  'is-valid': formik.touched.matchStatus && !formik.errors.matchStatus,
                }
              )}
            />
            {formik.touched.matchStatus && formik.errors.matchStatus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.matchStatus}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* <a
            className='btn btn-danger w-100 mb-5'
            data-bs-toggle='modal'
            href='#exampleModalToggle'
            role='button'
          >
            MANUAL MATCHING
          </a>
          <ManualMatching transData={transData} /> */}
        </form>
      </div>
    </div>
  )
}

export default TransactionManagementPage_View
