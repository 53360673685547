import axios from 'axios'
import {stringify} from 'querystring'
import {useState, useEffect} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {usePendingWithdrawalStore} from '../Withdrawal-Pending-Page/Withdrawal-Pending-Store'

export default function ExportWithdrawal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let selectedArray: any = props.selectedArray
  let setSelectedArray: any = props.setSelectedArray
  let withdrawalFilterSubmit = props.withdrawalFilterSubmit

  //Zustand Store
  const setGetData = usePendingWithdrawalStore((state: any) => state.setGetData)

  function fallbackCopyToClipboard(text: string) {
    const input = document.createElement('textarea')
    input.style.position = 'fixed'
    input.style.opacity = '0'
    input.value = text.replace(/\n/g, '\r\n') // Replace \n with \r\n for line breaks
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }

  function bulkExportWithdrawal(selectedArray: any) {
    if (selectedArray.length !== 0) {
      axios
        .post(
          `${SISPAY_API}/cash/withdrawalrequest/export`,
          {
            request: {
              session: session,
              withdrawalReqs: selectedArray,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let result = response.data.result['clipboardString']
          if (response.data.code == '200') {
            withdrawalFilterSubmit.current.click()
            fallbackCopyToClipboard(result)
            setGetData(true)
            alert(result)
          } else {
            setGetData(true)
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    }
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_export'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Export Withdrawal
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => setSelectedArray([])}
            ></button>
          </div>
          <div className='modal-body d-flex flex-wrap'>
            {selectedArray.length > 0
              ? selectedArray.map((data: any, index: number) => {
                  return (
                    <div
                      className='d-flex d-flex-column me-5 mb-5'
                      style={{
                        width: 'auto',
                      }}
                    >
                      <div
                        style={{
                          width: 'auto',
                          backgroundColor: 'grey',
                          padding: '0 10px 0 10px',
                          color: 'white',
                          textAlign: 'center',
                          borderRadius: '5px 0 0 5px',
                        }}
                      >
                        {' '}
                        ID
                      </div>
                      <div
                        style={{
                          width: 'auto',
                          padding: '0 10px 0 10px',
                          backgroundColor: 'white',
                          color: 'black',
                          textAlign: 'center',
                          borderRadius: '0 5px 5px 0',
                        }}
                      >
                        {data.id}
                      </div>
                    </div>
                  )
                })
              : 'No Withdrawal Selected'}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-bs-dismiss='modal'
              onClick={() => setSelectedArray([])}
            >
              Close
            </button>
            {selectedArray.length > 0 && (
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  bulkExportWithdrawal(selectedArray)
                  setSelectedArray([])
                }}
              >
                Export
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
