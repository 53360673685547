import React, {Ref, useEffect, useRef, useState} from 'react'
import {Column, useTable, useResizeColumns, useFlexLayout} from 'react-table'
import {Styles} from '../../components/Table_Style'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {pushUniqueValue} from '../../components/PushUniqueValue'
import {checkValueExists} from '../../components/CheckValueExists'
import {tickAllCheckbox} from '../../components/TickAllCheckbox'
import ExportWithdrawal from '../components/Withdrawal-Export-Withdrawal-Modal'
import {RejectionModal} from '../components/Withdrawal-Reject-Modal'
import {ApprovalModal} from '../components/Withdrawal-Approval-Modal'
import Bulk_Withdrawal_Approval from '../components/Withdrawal-Bulk-Approval-Modal'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import {actionBtnCss} from '../components/Withdrawal-Action-Btn-Css'
import Withdrawal_Export_CSV from '../components/Withdrawal_Export_CSV'
import {metricsArray} from '../../components/MetricsArray'
import FormatNumber from '../../components/FormatNumber'
import scrollToTop from '../../components/ScrollToTop'
import {MatchSMSModal} from '../components/Withdrawal-Match-SMS-Modal'
import Bulk_Withdrawal_Upload from '../components/Withdrawal-Bulk-Withdrawal-Upload-Modal'
import {Filter_Bulk_Withdrawal} from './Withdrawal-Bulk-Withdrawal-Filter'
import {TableTemplateCSS} from '../../components/Table/TableCSS'
import TableTemplate from '../../components/Table/TableTemplate'
import {ColumnDef, ColumnPinningState} from '@tanstack/react-table'
import FormatDate from '../../components/FormatDate'
import IndeterminateCheckbox from '../../components/Table/IndeterminateCheckbox'

const Bulk_Withdrawal: React.FC = () => {
  //Table Related
  const [rowSelection, setRowSelection] = React.useState({})
  const [totalCount, setTotalCount] = useState(0)
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['select'],
    right: ['action'],
  })
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 200, //default page size
  })
  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => row)

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])
  //Table Related

  //States
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [currentRejectedId, setCurrentRejectedId] = useState('')
  const [selectedWalletCdFilter, setSelectedWalletCdFilter] = useState('')
  const [currentWalletCd, setCurrentWalletCd] = useState('')
  const [metrics, setMatrics] = useState<any>(undefined)
  const [totalFormData, setTotalFormData] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>({
    walletAccNo: '',
    walletCd: '',
    withdrawalStatus: '',
    merchantTxnId: '',
  })

  //Refs
  const approvalRemark = useRef<any>()
  const rejectionRemark = useRef<any>()
  const withdrawalFilterSubmit = useRef<any>()

  // useEffect(() => {
  //   let metrics = localStorage.getItem('bulkWithdrawalMetrics')
  //   if (metrics) {
  //     setMatrics(JSON.parse(metrics))
  //   } else {
  //     setMatrics([])
  //   }
  // }, [formData, showMetrics])

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        enableColumnFilter: false,
        size: 50,
        header: ({table}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'Withdrawal Request ID',
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'withdrawalDT',
        header: 'Withdrawal DateTime',
        accessorKey: 'withdrawalDT',
        cell: (info) => FormatDate(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'completedDT',
        header: 'Completed DateTime',
        accessorKey: 'completedDT',
        cell: (info) => FormatDate(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'withdrawalStatusUpdateDT',
        header: 'Status Updated DT',
        accessorKey: 'withdrawalStatusUpdateDT',
        cell: (info) => FormatDate(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'merchantName',
        header: 'Merchant',
        accessorKey: 'merchantName',
        cell: (info) => info.getValue() + ' - ' + info.row.original.merchantCd,
        enableColumnFilter: false,
      },
      {
        id: 'customerCd',
        header: 'Customer Code',
        accessorKey: 'customerCd',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'destinationWalletAcc',
        header: 'Destination Account No',
        accessorKey: 'destinationWalletAcc',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'destinationWalletName',
        header: 'Destination Account Name',
        accessorKey: 'destinationWalletName',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'walletCd',
        header: 'Wallet Code',
        accessorKey: 'walletCd',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },

      {
        id: 'amount',
        header: 'Amount',
        accessorKey: 'amount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'matchStatus',
        header: 'Match Status',
        accessorKey: 'matchStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'withdrawalStatus',
        header: 'Status',
        accessorKey: 'withdrawalStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'callbackStatus',
        header: 'Result/ Response Status',
        accessorKey: 'callbackStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'withdrawalFee',
        header: 'Withdrawal Fee',
        accessorKey: 'withdrawalFee',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'approvalRejectionRemark',
        header: 'Approval/ Rejection Remark',
        accessorKey: 'approvalRejectionRemark',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'merchantTxnId',
        header: 'Merchant Transaction ID',
        accessorKey: 'merchantTxnId',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'approvedBy',
        header: 'Approved By',
        accessorKey: 'approvedBy',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'reference',
        header: 'Reference',
        accessorKey: 'reference',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        size: 100,
        cell: (info: any) => {
          let row: any = info.row.original

          return (
            <div className='d-flex'>
              <button
                type='button'
                disabled={selectedArray.length > 0 ? true : false}
                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop_approve'
                onClick={() => {
                  setSelectedRow(row)
                }}
                style={actionBtnCss(row.withdrawalStatus)}
              >
                {' '}
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={popover('Approve Withdrawal')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
              <button
                type='button'
                disabled={selectedArray.length > 0 ? true : false}
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop_reject'
                onClick={() => setCurrentRejectedId(row.id)}
                style={actionBtnCss(row.withdrawalStatus)}
              >
                {' '}
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={popover('Reject Withdrawal')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
            </div>
          )
        },
        enableColumnFilter: false,
      },
    ],
    []
  )

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <div className='fv-row me-5'>
            <button
              className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100 '
              disabled={formData.length > 0 && !downloadLoading ? false : true}
              onClick={() => {
                Withdrawal_Export_CSV(filterValues, setDownloadLoading)
              }}
            >
              {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
            </button>
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_bulk_success'
          >
            Bulk Success
          </button>{' '}
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_bulk_withdrawal_upload'
          >
            Bulk Withdrawal
          </button>{' '}
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter_Bulk_Withdrawal
        setFormData={setFormData}
        setSelectedArray={setSelectedArray}
        setSelectedWalletCdFilter={setSelectedWalletCdFilter}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
        setTotalFormData={setTotalFormData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setFilterValues={setFilterValues}
        downloadLoading={downloadLoading}
        setRowSelection={setRowSelection}
        pagination={pagination}
        setPagination={setPagination}
        firstLoad={firstLoad}
        setFirstLoad={setFirstLoad}
        setTotalCount={setTotalCount}
      />
      <MatchSMSModal />
      <Bulk_Withdrawal_Upload />
      <TableTemplateCSS>
        <TableTemplate
          data={formData}
          columns={columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnPinning={columnPinning}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          showMetrics={showMetrics}
          manualPagination={true}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={totalCount}
        />
      </TableTemplateCSS>
      <ApprovalModal
        selectedRow={selectedRow}
        approvalRemark={approvalRemark}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
      <RejectionModal
        currentRejectedId={currentRejectedId}
        rejectionRemark={rejectionRemark}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
      {/* Modal */}
      <ExportWithdrawal selectedArray={selectedArray} setSelectedArray={setSelectedArray} />
      <Bulk_Withdrawal_Approval
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedWalletCdFilter={selectedWalletCdFilter}
        withdrawalFilterSubmit={withdrawalFilterSubmit}
      />
    </div>
  )
}

export default Bulk_Withdrawal
