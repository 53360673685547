import React, {Ref, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column, useTable, useFlexLayout, useResizeColumns, useFilters} from 'react-table'
import {KTSVG} from '../../../../_metronic/helpers'
import {Styles} from '../../components/Table_Style'
import {Filter} from './components/filter'
import axios from 'axios'
import $ from 'jquery'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import {ColumnFilter} from '../../components/ColumnFilter'
import {metricsArray} from '../../components/MetricsArray'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {WalletAssignment_ExportPDF} from './components/WalletAssignment_ExportPDF'
import {useWalletAssignmentStore} from './components/WalletAssignment_Store'

const WalletAssignmentPage: React.FC = () => {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const API_URL = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [downloadAction, setDownloadAction] = useState(false)
  const [currentUnassignWallet, setCurrentUnassignWallet] = useState<Wallet>({
    id: '',
    walletAccNo: '',
    walletCd: '',
  })

  //Zustand Store
  const setGetData = useWalletAssignmentStore((state: any) => state.setGetData)

  interface Wallet {
    id: string
    walletAccNo: string
    walletCd: string
  }

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  useEffect(() => {
    let metrics = localStorage.getItem('walletAssignmentMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table
  interface Data {
    id: string
    walletAccName: string
    walletAccNo: string
    walletCd: string
    merchantCd: string
    status: string
    walletAssignDate: string
  }

  const keyMap = {
    id: 'ID',
    walletAccName: 'Wallet Account Name',
    walletAccNo: 'Wallet Account No',
    walletCd: 'Wallet Code',
    merchantCd: 'Merchant Code',
    status: 'Status',
    walletAssignDate: 'Assignment DT',
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
        Filter: ColumnFilter,
      },
      {
        Header: 'Wallet Code',
        accessor: (row: any) => {
          return row.walletCd
        },
        disableFilters: true,
      },
      {
        Header: 'Merchant Code',
        accessor: 'merchantCd',
        disableFilters: true,
      },
      {
        Header: 'Status',
        disableFilters: true,
        accessor: 'status',
      },
      {
        Header: 'Assignment DT',
        disableFilters: true,
        accessor: (row: any) => {
          return row.walletAssignDate.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Action',
        disableFilters: true,

        accessor: (row: any) => {
          return (
            <div
              style={{
                position: 'sticky',
                right: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                padding: '0',
                height: '100%',
              }}
            >
              <a
                className='btn btn-icon btn-bg-danger btn-active-color-white btn-sm me-1'
                data-bs-toggle='modal'
                href='#walletUnassign'
                role='button'
                onClick={() => {
                  setCurrentUnassignWallet(row)
                }}
              >
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Unassign Wallet')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <i className='bi bi-trash3-fill'></i>{' '}
                  </div>
                </OverlayTrigger>{' '}
              </a>
              <Link
                to={'/wallet/assignment/edit/' + row.id}
                state={row}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() => setGetData(true)}
              >
                <OverlayTrigger trigger='hover' placement='top' overlay={popover('Edit')}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 150,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFilters,
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('walletAssignmentMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  function submitUnassignWallet() {
    if (currentUnassignWallet.id !== '') {
      axios
        .post(
          `${API_URL}/wallet/unassign`,
          {
            request: {
              id: currentUnassignWallet.id,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            alert('Unassigned Successfully')
            setGetData(true)
            // $('#walletAssignResetBtn').trigger('click')
          }
        })
        .catch(function (error) {
          alert(error)
        })
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setDownloadAction(!downloadAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {downloadAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_Assigment'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_Assignment'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    WalletAssignment_ExportPDF(
                      formData,
                      getCurrentDateInput(),
                      getCurrentDateInput()
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
          <Link
            to='/wallet/assign'
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary ms-3 me-5'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-plus me-1'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
            </svg>
            Wallet Assignment
          </Link>{' '}
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter setFormData={setFormData} />
      <Styles>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
      <div
        className='modal fade'
        id='walletUnassign'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                Unassign Wallet
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              Do you wish to unassign{' '}
              <span className='fw-bold text-decoration-underline'>
                {currentUnassignWallet.walletAccNo} - {currentUnassignWallet.walletCd}
              </span>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  submitUnassignWallet()
                }}
              >
                Unassign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletAssignmentPage
