import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {searching} from '../../../components/Searching'
import {Overlay} from '../../../components/Overlay'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {useAuth} from '../../../auth/core/Auth'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {useWalletUtilizationStore} from './WalletUtilization_Store'
import sortByNumber from '../../../components/SortByAmount'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {sortByName} from '../../../components/SortByName'

export function Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState([])

  //Zustand Store
  const filterOptions = useWalletUtilizationStore((state: any) => state.filterOptions)
  const setFilterOptions = useWalletUtilizationStore((state: any) => state.setFilterOptions)

  const selectMerchantCdRef = useRef<any>(null)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    status: filterOptions.status ? String(filterOptions.status) : '',
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
    queryDate: filterOptions.queryDate ? String(filterOptions.queryDate) : getCurrentDateInput(),
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
  })

  const keys = ['walletAccName', 'walletAccNo']

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let search = values.search.trim()
      let status = values.status.trim()
      let walletCd = values.walletCd.trim()
      let queryDate = values.queryDate.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/wallet/utilisation/list`,
          {
            request: {
              queryDate: queryDate,
              session: session,
              ...(values.merchantCd && {merchantCd: values.merchantCd}),
              ...(values.walletCd && {walletCd: values.walletCd}),
              ...(values.status && {status: values.status}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            let data = response.data.result.walletUtilisationList

            if (search !== '') {
              data = searching(data, search.toLowerCase(), keys)
            }

            setFormData(sortByNumber(data, 'walletBal'))
            setDisabled(false)
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    },
  })

  useEffect(() => {
    axios
      .post(
        `${SISPAY_API}/merchant/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.merchant
          // Extract merchantCd and form a new array of JSON objects
          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )
          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        // alert(error)
      })
  }, [])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {' '}
                      {/* begin::Form group Search */}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        {Overlay('SEARCH Wallet Account Name, Wallet Account No')}
                      </div>
                      <input
                        placeholder='Enter your search'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      <div
                        className='fv-row mb-5'
                        style={{
                          zIndex: 9999,
                        }}
                      >
                        <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>
                        <Select
                          ref={selectMerchantCdRef}
                          className='basic-single'
                          classNamePrefix='select'
                          options={merchantCdList}
                          styles={customStyles}
                          isClearable={true}
                          name='merchantCd'
                          value={
                            formik.values.merchantCd
                              ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                              : null
                          }
                          onChange={(e: any) =>
                            e !== null
                              ? formik.setFieldValue('merchantCd', e.value)
                              : formik.setFieldValue('merchantCd', '')
                          }
                        />
                      </div>{' '}
                    </div>
                    <div className='col'>
                      {/* begin::Form group User type */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                        <select
                          {...formik.getFieldProps('walletCd')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='935'>Tap & Go</option>
                          <option value='003'>Standard Chartered Bank Hong Kong Branch</option>
                          <option value='004'>
                            The Hongkong and Shanghai Banking Corporation Limited
                          </option>
                          <option value='387'>ZA Bank Limited</option>
                          <option value='388'>Livi Bank Limited</option>
                          <option value='012'>Bank of China (Hong Kong) Limited</option>
                          <option value='393'>Agro Ant Bank (Hong Kong) Limited</option>
                          <option value='389'>Mox Bank Limited</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Status */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Status</label>
                        <select
                          {...formik.getFieldProps('status')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='ACTIVE'>ACTIVE</option>
                          <option value='INACTIVE'>INACTIVE</option>
                          <option value='DEACTIVE'>DE-ACTIVE</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group DateTime */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Date</label>
                        <input
                          placeholder='Enter your date'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('queryDate')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.queryDate && formik.errors.queryDate,
                            },
                            {
                              'is-valid': formik.touched.queryDate && !formik.errors.queryDate,
                            }
                          )}
                        />
                        {formik.touched.queryDate && formik.errors.queryDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.queryDate}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() =>
                        formik.resetForm({
                          values: {
                            search: '',
                            status: '',
                            walletCd: '',
                            queryDate: getCurrentDateInput(),
                            merchantCd: '',
                          },
                        })
                      }
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
