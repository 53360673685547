import axios from 'axios'
import {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import {usePendingWithdrawalStore} from '../Withdrawal-Pending-Page/Withdrawal-Pending-Store'
import {useWithdrawalStore} from '../Withdrawal-Page/Withdrawal_Store'
import {useBulkWithdrawalStore} from '../Withdrawal-Bulk-Withdrawal-Page/Withdrawal-Bulk-Withdrawal-Store'
import html2canvas from 'html2canvas'
import FormatNumber from '../../components/FormatNumber'
import LoadingSpinner from '../../components/Loading-Spinner'

export function ApprovalModal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const API_URL = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let approvalRemark = props.approvalRemark
  let selectedRow = props.selectedRow

  //States
  const [walletAccNo, setWalletAccNo] = useState('')
  const [walletAccNoList, setWalletAccNoList] = useState(Object)
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('')

  const walletAccNoRef = useRef<any>()
  const receiptBody = useRef<any>()

  //Zustand Store
  const setPendingGetData = usePendingWithdrawalStore((state: any) => state.setGetData)
  const setGetData = useWithdrawalStore((state: any) => state.setGetData)
  const setBulkGetData = useBulkWithdrawalStore((state: any) => state.setGetData)

  // Get the current URL
  var currentUrl = window.location.href

  function verifyCurrentUrl(currentUrl: string) {
    if (currentUrl.includes('withdrawal/list')) {
      setGetData(true)
    } else if (currentUrl.includes('/withdrawal/pending')) {
      setPendingGetData(true)
    } else if (currentUrl.includes('/withdrawal/bulk-approval')) {
      setBulkGetData(true)
    } else {
      console.log('Current URL does not match the specified patterns')
    }
  }

  function approveWithdrawal() {
    setLoading(true)
    axios
      .post(API_URL + '/cash/withdrawalrequest/approve', {
        request: {
          id: selectedRow.id,
          session: session,
          walletAccNo: walletAccNo,
          approvalRejectionRemark: approvalRemark.current?.value,
        },
      })
      .then((response) => {
        verifyCurrentUrl(currentUrl)
        approvalRemark.current.value = ''
        walletAccNoRef.current.setValue('')
        setWalletAccNo('')
        setSubmit(true)
        setLoading(false)
        setResult(response.data.status)
        alert(response.data.message)
      })
      .catch((error) => {
        verifyCurrentUrl(currentUrl)
        alert(error)
      })
  }

  function retrieveAgent() {
    axios
      .post(API_URL + '/wallet/list', {
        request: {
          session: session,
        },
      })
      .then((response) => {
        let result = response.data.result['wallet']
        const walletList = Array.from(
          new Set(
            result
              .filter(
                (wallet: any) =>
                  wallet.status == 'ACTIVE' && wallet.walletCd == selectedRow.walletCd
              )
              .map((wallet: any) => wallet.walletAccNo)
          )
        ).map((walletAccNo) => ({value: walletAccNo, label: walletAccNo}))

        setWalletAccNoList(walletList)
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    retrieveAgent()
  }, [selectedRow.walletCd])

  const handleDownloadImage = async (selectedRow: any) => {
    const element = receiptBody.current
    const canvas = await html2canvas(element)

    const data = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = data
      link.download = `${selectedRow.destinationWalletAcc} - ${selectedRow.destinationWalletName}.jpg`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(data)
    }
  }

  function approveModalComponent() {
    return (
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Do you want to approve withdrawal ID: {selectedRow.id}?
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex flex-column'>
              <label className='form-label required fw-bolder text-dark fs-6'>
                Select Wallet : {selectedRow.walletCd}
              </label>
              <div>
                <Select
                  ref={walletAccNoRef}
                  className='basic-single mb-3'
                  classNamePrefix='select'
                  options={walletAccNoList}
                  styles={customStyles}
                  name='merchantCd'
                  onChange={(e: any) => setWalletAccNo(e.value)}
                />
              </div>
              <label className='form-label fw-bolder text-dark fs-6'>Remark</label>
              <textarea
                name='approvalRemark'
                ref={approvalRemark}
                id='approvalRemark'
                className='w-100'
                rows={5}
              ></textarea>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              className='btn btn-success'
              disabled={walletAccNo == '' || loading}
              onClick={approveWithdrawal}
              // onClick={() => setSubmit(true)}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    )
  }

  function receiptComponent() {
    return (
      <div
        className='modal-dialog modal-dialog-centered'
        style={{
          fontFamily: 'Roboto',
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              {'Successfully Approve Withdrawal ID: ' + selectedRow.id + '!'}
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => setSubmit(false)}
            ></button>
          </div>
          <div className='modal-body'>
            {LoadingSpinner(loading)}
            <div
              className='flex-column justify-content-center align-items-center bg-white'
              ref={receiptBody}
              style={{
                paddingTop: '0px',
                paddingBottom: '20px',
                width: '400px',
                height: '900px',
                display: loading ? 'none' : 'flex',
                position: 'relative',
              }}
            >
              <img
                src='/media/imgs/ct_success.png'
                alt=''
                style={{width: '50%', marginBottom: '50px'}}
              />
              <div className='text-gray-500 mb-5' style={{fontSize: '18px'}}>
                Successful
              </div>
              <div
                className='text-gray-700 mb-5'
                style={{fontSize: '12px', letterSpacing: '0.5px'}}
              >
                Transferred to the recipient account
              </div>
              <div className='text-gray-500 mb-2 text-uppercase' style={{fontSize: '18px'}}>
                {selectedRow.destinationWalletName}
              </div>
              <div className='text-gray-700 mb-5' style={{fontSize: '12px'}}>
                {selectedRow.walletCd == 'BKASH'
                  ? `+852 ${selectedRow.destinationWalletAcc}`
                  : selectedRow.destinationWalletAcc}
              </div>
              <div className='text-gray-500 mb-5' style={{fontSize: '18px'}}>
                HKD {FormatNumber(selectedRow.amount)}
              </div>
              <div
                className='btn'
                style={{
                  backgroundColor: '#d9501a',
                  width: '90%',
                  height: '40x',
                  borderRadius: '25px',
                  pointerEvents: 'none',
                  position: 'absolute',
                  bottom: '20px',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                OK
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-bs-dismiss='modal'
              onClick={() => setSubmit(false)}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-success'
              // data-bs-dismiss='modal'
              onClick={() => handleDownloadImage(selectedRow)}
            >
              Download Receipt
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className='modal fade'
      id='staticBackdrop_approve'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      {submit ? receiptComponent() : approveModalComponent()}
    </div>
  )
}
