import {useRef, useState} from 'react'
import html2canvas from 'html2canvas'
import FormatNumber from '../../components/FormatNumber'
import LoadingSpinner from '../../components/Loading-Spinner'

export function Withdrawal_Receipt_Modal(props: any) {
  //Props
  let selectedRow = props.selectedRow

  //States
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  const receiptBody = useRef<any>()

  const handleDownloadImage = async (selectedRow: any) => {
    const element = receiptBody.current
    const canvas = await html2canvas(element)

    const data = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = data
      link.download = `${selectedRow.destinationWalletAcc} - ${selectedRow.destinationWalletName}.jpg`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(data)
    }
  }

  function receiptComponent() {
    return (
      <div
        className='modal-dialog modal-dialog-centered'
        style={{
          fontFamily: 'Roboto',
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              {'Successfully Approve Withdrawal ID: ' + selectedRow.id + '!'}
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => setSubmit(false)}
            ></button>
          </div>
          <div className='modal-body'>
            {LoadingSpinner(loading)}
            <div
              className='flex-column justify-content-center align-items-center bg-white'
              ref={receiptBody}
              style={{
                paddingTop: '0px',
                paddingBottom: '20px',
                width: '400px',
                height: '900px',
                display: loading ? 'none' : 'flex',
                position: 'relative',
              }}
            >
              <img
                src='/media/imgs/ct_success.png'
                alt=''
                style={{width: '50%', marginBottom: '50px'}}
              />
              <div className='text-gray-500 mb-5' style={{fontSize: '18px'}}>
                Successful
              </div>
              <div
                className='text-gray-700 mb-5'
                style={{fontSize: '12px', letterSpacing: '0.5px'}}
              >
                Transferred to the recipient account
              </div>
              <div className='text-gray-500 mb-2 text-uppercase' style={{fontSize: '18px'}}>
                {selectedRow.destinationWalletName}
              </div>
              <div className='text-gray-700 mb-5' style={{fontSize: '12px'}}>
                {selectedRow.walletCd == 'BKASH'
                  ? `+852 ${selectedRow.destinationWalletAcc}`
                  : selectedRow.destinationWalletAcc}
              </div>
              <div className='text-gray-500 mb-5' style={{fontSize: '18px'}}>
                HKD {FormatNumber(selectedRow.amount)}
              </div>
              <div
                className='btn'
                style={{
                  backgroundColor: '#d9501a',
                  width: '90%',
                  height: '40x',
                  borderRadius: '25px',
                  pointerEvents: 'none',
                  position: 'absolute',
                  bottom: '20px',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                OK
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-bs-dismiss='modal'
              onClick={() => setSubmit(false)}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-success'
              // data-bs-dismiss='modal'
              onClick={() => handleDownloadImage(selectedRow)}
            >
              Download Receipt
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className='modal fade'
      id='withdrawalReceiptModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      {receiptComponent()}
    </div>
  )
}
