import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../../auth/core/Auth'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import axios from 'axios'
import {sortByName} from '../../components/SortByName'
import {ErrorHandling} from '../../components/ErrorHandling'
import getTimestampInSec from '../../components/GetTimeInSeconds'
import getTimestamp from '../../components/GetTimestamp'
import sha1 from 'crypto-js/sha1'
import qs from 'qs'

const ManualDepositCreate_Merchant: React.FC = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [loading, setLoading] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [merchantCdList, setMerchantCdList] = useState([])

  //Get Data
  function retrieveMerchant(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )
          setMerchants(result)
          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  function SD_Generate() {
    let MerchantCode = formik.values.merchantCd
    let CustomerCode = formik.values.customerCd
    let CurrencyCode = formik.values.currency
    let SecureCode = formik.values.secureCd
    let BankCode = formik.values.bankCode
    let Amount: any = formik.values.amount
    let TimeStamp = formik.values.timestamp

    let combinedString = `${Amount}${BankCode}${CurrencyCode}${SecureCode}${MerchantCode}${CustomerCode}${TimeStamp}`
    let signature = sha1(combinedString).toString()
    formik.setFieldValue('signature', signature)
    return signature
  }

  useEffect(() => {
    retrieveMerchant(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  const initialValues = {
    depositType: 'singleDeposit',
    merchantCd: '',
    secureCd: '',
    customerCd: 'CC' + getTimestampInSec(),
    phoneNo: '0000000',
    currency: 'HKD',
    bankCode: '',
    amount: '',
    languageCode: 'EN',
    sourceAccNo: '',
    sourceAccOptions: '',
    transId: 'TID' + getTimestampInSec(),
    reference: '',
    timestamp: getTimestamp(),
    clientIp: '0.0.0.0',
    signature: '',
    responseUrl: 'www.google.com',
    backendUrl: 'www.google.com',
  }

  const ManualDepositCreateSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: ManualDepositCreateSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let data: any = {
        MerchantCode: values.merchantCd,
        CustomerCode: values.customerCd,
        PhoneNumber: values.phoneNo,
        Currency: values.currency,
        BankCode: values.bankCode,
        Amount: values.amount,
        LanguageCode: values.languageCode,
        TransactionId: values.transId,
        Reference: values.reference,
        Timestamp: values.timestamp,
        ClientIP: values.clientIp,
        Signature: SD_Generate(),
        ResponseURL: values.responseUrl,
        BackendURL: values.backendUrl,
      }

      if (values.depositType == 'singleDeposit') {
        data.SourceAccNo = values.sourceAccNo
      } else if (values.depositType == 'multiDeposit') {
        data.SourceAccountOption = values.sourceAccOptions
      }

      data = qs.stringify(data)

      const SingleDepositUrl = `${SISPAY_API}/cash/depositrequest/create`
      const MultiDepositUrl = `${SISPAY_API}/cash/depositrequest/create2`

      // Create a form element
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = values.depositType == 'singleDeposit' ? SingleDepositUrl : MultiDepositUrl
      form.style.display = 'none' // Hide the form

      // Helper function to create and append hidden input fields
      const addHiddenField = (name: string, value: string) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      }

      // Add hidden input fields for each parameter
      addHiddenField('MerchantCode', values.merchantCd)
      addHiddenField('CustomerCode', values.customerCd)
      addHiddenField('PhoneNumber', values.phoneNo)
      addHiddenField('Currency', values.currency)
      addHiddenField('BankCode', values.bankCode)
      addHiddenField('Amount', values.amount)
      addHiddenField('LanguageCode', values.languageCode)
      if (values.depositType == 'singleDeposit') {
        addHiddenField('SourceAccNo', values.sourceAccNo)
      } else if (values.depositType == 'multiDeposit') {
        addHiddenField('SourceAccountOption', values.sourceAccOptions)
      }
      addHiddenField('TransactionId', values.transId)
      addHiddenField('Reference', values.reference)
      addHiddenField('Timestamp', values.timestamp)
      addHiddenField('ClientIP', values.clientIp)
      addHiddenField('Signature', SD_Generate())
      addHiddenField('ResponseURL', values.responseUrl)
      addHiddenField('BackendURL', values.backendUrl)

      // Append the form to the document body
      document.body.appendChild(form)

      // Submit the form (you may choose to trigger this on a button click or as needed)
      form.submit()
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-between'>
        {/* <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a> */}
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Deposit Type */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Deposit Type</label>
            <select
              {...formik.getFieldProps('depositType')}
              className={clsx('form-control bg-transparent')}
              data-placeholder=''
            >
              <option value='singleDeposit'>Single Source</option>
              <option value='multiDeposit'>Multiple Source</option>
            </select>
            {formik.touched.depositType && formik.errors.depositType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.depositType}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Merchant Code</label>
            <Select
              className='basic-single'
              classNamePrefix='select'
              options={merchantCdList}
              styles={customStyles}
              isClearable={true}
              name='merchantCd'
              onChange={(e: any) => {
                if (e !== null) {
                  formik.setFieldValue('merchantCd', e.value)
                  const secureCd = merchants
                    .filter((item: any) => item.merchantCd === e.value)
                    .map((item: any) => item.secureCd)
                  formik.setFieldValue('secureCd', secureCd)
                } else {
                  formik.setFieldValue('merchantCd', '')
                  formik.setFieldValue('secureCd', '')
                }
              }}
            />
            {formik.touched.merchantCd && formik.errors.merchantCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchantCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Secure Code */}
          <div className='fv-row mb-8 d-none'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Secure Code</label>
            <input
              placeholder='Enter Secure Code'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('secureCd')}
              value={merchants
                .filter((item: any) => item.merchantCd === formik.values.merchantCd)
                .map((item: any) => item.secureCd)}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.secureCd && formik.errors.secureCd,
                },
                {
                  'is-valid': formik.touched.secureCd && !formik.errors.secureCd,
                }
              )}
            />
            {formik.touched.secureCd && formik.errors.secureCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.secureCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Customer Code</label>
            <input
              placeholder='Enter Customer Code'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Phone Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Phone Number</label>
            <input
              placeholder='Enter Phone Number'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('phoneNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.phoneNo && formik.errors.phoneNo,
                },
                {
                  'is-valid': formik.touched.phoneNo && !formik.errors.phoneNo,
                }
              )}
            />
            {formik.touched.phoneNo && formik.errors.phoneNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Currency */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Currency</label>
            <input
              placeholder='Enter Currency'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('currency')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.currency && formik.errors.currency,
                },
                {
                  'is-valid': formik.touched.currency && !formik.errors.currency,
                }
              )}
            />
            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.currency}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Bank Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Bank Code</label>
            <select
              {...formik.getFieldProps('bankCode')}
              className={clsx('form-control bg-transparent')}
              data-placeholder=''
            >
              <option value=''>Select Bank Code</option>
              {/* <option value='NAGAD'>NAGAD</option> */}
              <option value='BKASH'>TapNGo</option>
              {/* <option value='ROCKET'>ROCKET</option> */}
            </select>
            {formik.touched.bankCode && formik.errors.bankCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Amount</label>
            <input
              placeholder='Enter Amount'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Language Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Language Code</label>
            <input
              placeholder='Enter Language Code'
              autoComplete='off'
              {...formik.getFieldProps('languageCode')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.languageCode && formik.errors.languageCode,
                },
                {
                  'is-valid': formik.touched.languageCode && !formik.errors.languageCode,
                }
              )}
            />
            {formik.touched.languageCode && formik.errors.languageCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.languageCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Source Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Source Account Number
            </label>
            <input
              placeholder='0000000'
              autoComplete='off'
              disabled={formik.values.depositType == 'singleDeposit' ? false : true}
              {...formik.getFieldProps('sourceAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.sourceAccNo && formik.errors.sourceAccNo,
                },
                {
                  'is-valid': formik.touched.sourceAccNo && !formik.errors.sourceAccNo,
                }
              )}
            />
            {formik.touched.sourceAccNo && formik.errors.sourceAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sourceAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Source Account Options */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Source Account Options
            </label>
            <input
              placeholder='0000000|1111111|2222222'
              autoComplete='off'
              disabled={formik.values.depositType == 'multiDeposit' ? false : true}
              {...formik.getFieldProps('sourceAccOptions')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.sourceAccOptions && formik.errors.sourceAccOptions,
                },
                {
                  'is-valid': formik.touched.sourceAccOptions && !formik.errors.sourceAccOptions,
                }
              )}
            />
            {formik.touched.sourceAccOptions && formik.errors.sourceAccOptions && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sourceAccOptions}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Merchant Transaction ID
            </label>
            <input
              placeholder='Enter Merchant Transaction ID'
              autoComplete='off'
              {...formik.getFieldProps('transId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.transId && formik.errors.transId,
                },
                {
                  'is-valid': formik.touched.transId && !formik.errors.transId,
                }
              )}
            />
            {formik.touched.transId && formik.errors.transId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.transId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Reference */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Reference</label>
            <input
              placeholder='Enter Reference'
              autoComplete='off'
              {...formik.getFieldProps('reference')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.reference && formik.errors.reference,
                },
                {
                  'is-valid': formik.touched.reference && !formik.errors.reference,
                }
              )}
            />
            {formik.touched.reference && formik.errors.reference && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.reference}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Timestamp */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Timestamp</label>
            <input
              placeholder='Enter Timestamp'
              autoComplete='off'
              {...formik.getFieldProps('timestamp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.timestamp && formik.errors.timestamp,
                },
                {
                  'is-valid': formik.touched.timestamp && !formik.errors.timestamp,
                }
              )}
            />
            {formik.touched.timestamp && formik.errors.timestamp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.timestamp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Client IP */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Client IP</label>
            <input
              placeholder='Enter Client IP'
              autoComplete='off'
              {...formik.getFieldProps('clientIp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.clientIp && formik.errors.clientIp,
                },
                {
                  'is-valid': formik.touched.clientIp && !formik.errors.clientIp,
                }
              )}
            />
            {formik.touched.clientIp && formik.errors.clientIp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.clientIp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Response URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Response URL</label>
            <input
              placeholder='Enter Response URL'
              autoComplete='off'
              {...formik.getFieldProps('responseUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.responseUrl && formik.errors.responseUrl,
                },
                {
                  'is-valid': formik.touched.responseUrl && !formik.errors.responseUrl,
                }
              )}
            />
            {formik.touched.responseUrl && formik.errors.responseUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.responseUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Backend URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Backend URL</label>
            <input
              placeholder='Enter Backend URL'
              autoComplete='off'
              {...formik.getFieldProps('backendUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.backendUrl && formik.errors.backendUrl,
                },
                {
                  'is-valid': formik.touched.backendUrl && !formik.errors.backendUrl,
                }
              )}
            />
            {formik.touched.backendUrl && formik.errors.backendUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.backendUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-success w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default ManualDepositCreate_Merchant
