import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import {customStyles} from '../../components/CustomStyles'
import Select from 'react-select'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth'
import {sortByName} from '../../components/SortByName'

const WalletListPage_Edit: React.FC = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  let formData: any = location.state
  let username: string = localStorage.getItem('username') || ''

  console.log(username)
  console.log(username.includes('itadmin'))

  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const [loading, setLoading] = useState(false)
  const [navAssign, setNavAssign] = useState(Boolean)
  const [agentCdOptions, setAgentCdOptions] = useState([])

  useEffect(() => {
    axios
      .post(
        `${SISPAY_API}/user/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.users
          let user: object
          let users: any = []

          for (let i = 0; i < result.length; i++) {
            if (result[i].userType == 'Agent') {
              user = {
                value: result[i].id,
                label: result[i].username,
              }
              users.push(user)
            }
          }

          users = Array.from(new Set(users))
          setAgentCdOptions(sortByName(users, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }, [])

  const initialValues = {
    id: String(formData.id),
    walletAccName: String(formData.walletAccName),
    walletAccNo: String(formData.walletAccNo),
    walletCd: String(formData.walletCd),
    status: String(formData.status),
    walletType: String(formData.walletType),
    dailyDepositLmt: String(formData.dailyDepositLmt),
    dailyWithdrawalLmt: String(formData.dailyWithdrawalLmt),
    mobileNo: String(formData.mobileNo),
    minDeposit: String(formData.minDeposit),
    maxDeposit: String(formData.maxDeposit),
    minWithdrawal: String(formData.minWithdrawal),
    maxWithdrawal: String(formData.maxWithdrawal),
    prefAcc: formData.prefAcc,
    weightage: formData.weightage == undefined ? '' : String(formData.weightage),
    startBal: String(formData.startBal),
    balDiff: String(formData.balDiff),
    merchantName: String(formData.merchantName),
    agentName: String(formData.agentName),
    walletBal: '',
    session: '',
    isAgentWallet: formData.isAgentWallet == undefined ? '' : String(formData.isAgentWallet),
    agentCd: String(formData.agentCd),
    telco: formData.telco == undefined ? '' : String(formData.telcoName),
    pinNum: formData.pinNum == undefined ? '' : String(formData.pinNumber),
  }

  const editUserSchema = Yup.object().shape({
    walletAccName: Yup.string(),
    walletAccNo: Yup.string().required('Wallet Account Number is required'),
    walletCd: Yup.string().required('Wallet Code is required'),
    status: Yup.string().required('Status is required'),
    walletType: Yup.string().required('Wallet Type is required'),
    dailyDepositLmt: Yup.string().required('Daily Deposit Limit is required'),
    dailyWithdrawalLmt: Yup.string().required('Daily Withdrawal Limit is required'),
    mobileNo: Yup.string().required('Mobile Number is required'),
    minDeposit: Yup.string().required('Minimum Deposit is required'),
    maxDeposit: Yup.string().required('Maximum Deposit is required'),
    minWithdrawal: Yup.string().required('Minimum Withdrawal is required'),
    maxWithdrawal: Yup.string().required('Maximum Withdrawal is required'),
    prefAcc: Yup.boolean().required('Preferred Account is required'),
    weightage: Yup.string().when('prefAcc', {
      is: true,
      then: Yup.string().required('Weightage is required'),
    }),
    startBal: Yup.string().required('Starting Balance is required'),
    balDiff: Yup.string().required('Balance Different is required'),
    walletBal: Yup.string(),
    isAgentWallet: Yup.boolean().required('Is Agent Wallet is required'),
    agentCd: Yup.string().when('isAgentWallet', {
      is: true,
      then: Yup.string().required('Agent Code is required'),
    }),
  })

  const editUserSchema_itadmin = Yup.object().shape({
    walletAccName: Yup.string(),
    walletAccNo: Yup.string().required('Wallet Account Number is required'),
    walletCd: Yup.string().required('Wallet Code is required'),
    status: Yup.string().required('Status is required'),
    walletType: Yup.string().required('Wallet Type is required'),
    dailyDepositLmt: Yup.string().required('Daily Deposit Limit is required'),
    dailyWithdrawalLmt: Yup.string().required('Daily Withdrawal Limit is required'),
    mobileNo: Yup.string().required('Mobile Number is required'),
    minDeposit: Yup.string().required('Minimum Deposit is required'),
    maxDeposit: Yup.string().required('Maximum Deposit is required'),
    minWithdrawal: Yup.string().required('Minimum Withdrawal is required'),
    maxWithdrawal: Yup.string().required('Maximum Withdrawal is required'),
    prefAcc: Yup.boolean().required('Preferred Account is required'),
    weightage: Yup.number().when('prefAcc', {
      is: true,
      then: Yup.number().required('Weightage is required'),
    }),
    startBal: Yup.string().required('Starting Balance is required'),
    balDiff: Yup.string().required('Balance Different is required'),
    walletBal: Yup.string(),
    isAgentWallet: Yup.boolean().required('Is Agent Wallet is required'),
    agentCd: Yup.number().when('isAgentWallet', {
      is: true,
      then: Yup.number().required('Agent Code is required'),
    }),
    telco: Yup.string(),
    pinNum: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: username.includes('itadmin') ? editUserSchema_itadmin : editUserSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let sb: any = values.startBal
        let bd: any = values.balDiff
        let different: any = sb - bd
        values.walletBal = different.toString()

        axios
          .post(
            `${SISPAY_API}/wallet/update`,
            {
              request: {
                id: values.id,
                session: session,
                walletAccName: values.walletAccName.trim(),
                dailyWithdrawalLmt: values.dailyWithdrawalLmt,
                walletAccNo: values.walletAccNo,
                maxWithdrawal: values.maxWithdrawal,
                maxDeposit: values.maxDeposit,
                walletType: values.walletType,
                walletBal: values.walletBal,
                balDiff: values.balDiff,
                walletCd: values.walletCd,
                dailyDepositLmt: values.dailyDepositLmt,
                startBal: values.startBal,
                minDeposit: values.minDeposit,
                minWithdrawal: values.minWithdrawal,
                status: values.status,
                prefAcc: values.prefAcc,
                weightage: values.weightage,
                agentCd: values.agentCd,
                isAgentWallet: values.isAgentWallet,
                ...(username.includes('itadmin') && {telcoName: values.telco}),
                ...(username.includes('itadmin') && {pinNumber: values.pinNum}),
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              if (navAssign === false) {
                setLoading(false)
                navigate(-1)
                alert('Wallet Updated!')
              } else {
                setLoading(false)
                alert('Wallet Updated!')
                navigate('/wallet/assign')
              }
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Edit Wallet
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Wallet Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Name
            </label>
            <input
              placeholder='Enter your Wallet Account Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccName')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletAccName && formik.errors.walletAccName,
                },
                {
                  'is-valid': formik.touched.walletAccName && !formik.errors.walletAccName,
                }
              )}
            />
            {formik.touched.walletAccName && formik.errors.walletAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Number
            </label>
            <input
              placeholder='Enter your Wallet Account Number'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('walletAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Code</label>
            <select
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='935'>Tap & Go</option>
              <option value='003'>Standard Chartered Bank Hong Kong Branch</option>
              <option value='004'>The Hongkong and Shanghai Banking Corporation Limited</option>
              <option value='387'>ZA Bank Limited</option>
              <option value='388'>Livi Bank Limited</option>
              <option value='012'>Bank of China (Hong Kong) Limited</option>
              <option value='393'>Agro Ant Bank (Hong Kong) Limited</option>
              <option value='389'>Mox Bank Limited</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          <div className='fv-row mb-8' hidden={!username.includes('itadmin')}>
            <label className='form-label required fw-bolder text-dark fs-6'>Telco</label>
            <input
              placeholder='Enter Telco'
              disabled={!username.includes('itadmin')}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('telco')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.telco && formik.errors.telco,
                },
                {
                  'is-valid': formik.touched.telco && !formik.errors.telco,
                }
              )}
            />
            {formik.touched.telco && formik.errors.telco && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.telco}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8' hidden={!username.includes('itadmin')}>
            <label className='form-label required fw-bolder text-dark fs-6'>Pin Number</label>
            <input
              placeholder='Enter Pin Number'
              type='text'
              autoComplete='off'
              disabled={!username.includes('itadmin')}
              {...formik.getFieldProps('pinNum')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.pinNum && formik.errors.pinNum,
                },
                {
                  'is-valid': formik.touched.pinNum && !formik.errors.pinNum,
                }
              )}
            />
            {formik.touched.pinNum && formik.errors.pinNum && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.pinNum}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Form group Status */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Status</label>
            <select
              {...formik.getFieldProps('status')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.status && formik.errors.status,
                },
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='ACTIVE'>ACTIVE</option>
              <option value='INACTIVE'>INACTIVE</option>
              <option value='DE-ACTIVE'>DE-ACTIVE</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Wallet Type */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Type</label>
            <select
              {...formik.getFieldProps('walletType')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletType && formik.errors.walletType,
                },
                {
                  'is-valid': formik.touched.walletType && !formik.errors.walletType,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='DEPOSIT'>Deposit</option>
              <option value='WITHDRAWAL'>Withdrawal</option>
              <option value='DEPOSITWITHDRAWAL'>Deposit & Withdrawal</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Daily Deposit Limit */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Daily Deposit Limit</label>
            <input
              placeholder='Enter Daily Deposit Limit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('dailyDepositLmt')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.dailyDepositLmt && formik.errors.dailyDepositLmt,
                },
                {
                  'is-valid': formik.touched.dailyDepositLmt && !formik.errors.dailyDepositLmt,
                }
              )}
            />
            {formik.touched.dailyDepositLmt && formik.errors.dailyDepositLmt && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dailyDepositLmt}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Daily Withdrawal Limit */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Daily Withdrawal Limit</label>
            <input
              placeholder='Enter Daily Withdrawal Limit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('dailyWithdrawalLmt')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    formik.touched.dailyWithdrawalLmt && formik.errors.dailyWithdrawalLmt,
                },
                {
                  'is-valid':
                    formik.touched.dailyWithdrawalLmt && !formik.errors.dailyWithdrawalLmt,
                }
              )}
            />
            {formik.touched.dailyWithdrawalLmt && formik.errors.dailyWithdrawalLmt && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dailyWithdrawalLmt}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Mobile Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>
            <input
              placeholder='Enter Mobile Number'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('mobileNo')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.mobileNo && formik.errors.mobileNo,
                },
                {
                  'is-valid': formik.touched.mobileNo && !formik.errors.mobileNo,
                }
              )}
            />
            {formik.touched.mobileNo && formik.errors.mobileNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobileNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Minimum Deposit */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Minimum Deposit</label>
            <input
              placeholder='Enter Minimum Deposit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('minDeposit')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.minDeposit && formik.errors.minDeposit,
                },
                {
                  'is-valid': formik.touched.minDeposit && !formik.errors.minDeposit,
                }
              )}
            />
            {formik.touched.minDeposit && formik.errors.minDeposit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.minDeposit}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Maximum Deposit */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Maximum Deposit</label>
            <input
              placeholder='Enter Maximum Deposit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('maxDeposit')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.maxDeposit && formik.errors.maxDeposit,
                },
                {
                  'is-valid': formik.touched.maxDeposit && !formik.errors.maxDeposit,
                }
              )}
            />
            {formik.touched.maxDeposit && formik.errors.maxDeposit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.maxDeposit}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Minimum Withdrawal */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Minimum Withdrawal</label>
            <input
              placeholder='Enter Minimum Withdrawal'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('minWithdrawal')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.minWithdrawal && formik.errors.minWithdrawal,
                },
                {
                  'is-valid': formik.touched.minWithdrawal && !formik.errors.minWithdrawal,
                }
              )}
            />
            {formik.touched.minWithdrawal && formik.errors.minWithdrawal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.minWithdrawal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Maximum Withdrawal */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Maximum Withdrawal</label>
            <input
              placeholder='Enter Maximum Withdrawal'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('maxWithdrawal')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.maxWithdrawal && formik.errors.maxWithdrawal,
                },
                {
                  'is-valid': formik.touched.maxWithdrawal && !formik.errors.maxWithdrawal,
                }
              )}
            />
            {formik.touched.maxWithdrawal && formik.errors.maxWithdrawal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.maxWithdrawal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Preferred Account */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Preferred Account
            </label>
            <select
              {...formik.getFieldProps('prefAcc')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.prefAcc && formik.errors.prefAcc,
                },
                {
                  'is-valid': formik.touched.prefAcc && !formik.errors.prefAcc,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='false'>NO</option>
              <option value='true'>YES</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Weightage */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Weightage</label>
            <input
              placeholder='Enter Weightage'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('weightage')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.weightage && formik.errors.weightage,
                },
                {
                  'is-valid': formik.touched.weightage && !formik.errors.weightage,
                }
              )}
            />
            {formik.touched.weightage && formik.errors.weightage && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.weightage}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Starting Balance */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Starting Balance</label>
            <input
              placeholder='Enter Starting Balance'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('startBal')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.startBal && formik.errors.startBal,
                },
                {
                  'is-valid': formik.touched.startBal && !formik.errors.startBal,
                }
              )}
            />
            {formik.touched.startBal && formik.errors.startBal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.startBal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Balance Different*/}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Balance Different</label>
            <input
              placeholder='Enter Balance Different'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('balDiff')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.balDiff && formik.errors.balDiff,
                },
                {
                  'is-valid': formik.touched.balDiff && !formik.errors.balDiff,
                }
              )}
            />
            {formik.touched.balDiff && formik.errors.balDiff && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.balDiff}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Is Agent Wallet */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Is Agent Wallet?</label>
            <select
              {...formik.getFieldProps('isAgentWallet')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.isAgentWallet && formik.errors.isAgentWallet,
                },
                {
                  'is-valid': formik.touched.isAgentWallet && !formik.errors.isAgentWallet,
                }
              )}
              data-placeholder=''
              onChange={(e: any) => {
                if (e.target.value == 'false') {
                  formik.setFieldValue('isAgentWallet', 'false')
                  formik.setFieldValue('agentName', '')
                  formik.setFieldValue('agentCd', '')
                } else {
                  formik.setFieldValue('isAgentWallet', 'true')
                }
              }}
            >
              <option value=''>Please Select</option>
              <option value='false'>NO</option>
              <option value='true'>YES</option>
            </select>
            {formik.touched.isAgentWallet && formik.errors.isAgentWallet && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.isAgentWallet}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Agent Code */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Agent Code</label>
            <Select
              //ref={agentCdSelectBtn}
              isDisabled={formik.getFieldProps('isAgentWallet').value == 'false' ? true : false}
              className='basic-single'
              classNamePrefix='select'
              options={agentCdOptions}
              styles={customStyles}
              isClearable={true}
              name='agentCd'
              onChange={(e: any) =>
                // e !== null
                //   ? formik.setFieldValue('agentCd', e.value)
                //   : formik.setFieldValue('agentCd', '')
                {
                  if (e !== null) {
                    formik.setFieldValue('agentName', e.label)
                    formik.setFieldValue('agentCd', e.value)
                  } else {
                    formik.setFieldValue('agentName', '')
                    formik.setFieldValue('agentCd', '')
                  }
                }
              }
              value={{
                label: formik.getFieldProps('agentName').value,
                value: formik.getFieldProps('agentCd').value,
              }}
            />
            {formik.touched.agentCd && formik.errors.agentCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.agentCd}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {!loading && <span className='indicator-label'>Update</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default WalletListPage_Edit
