import axios from 'axios'
import {getCurrentDateTime} from '../../components/GetCurrentDatetime'
import {modifyDT} from '../../components/ModifyDT'
import {modifyKeys} from '../../components/ModifyKeys'

const keyMap = {
  id: 'ID',
  withdrawalDT: 'Withdrawal DateTime',
  merchantName: 'Merchant Name',
  merchantCd: 'Merchant Code',
  customerCd: 'Customer Code',
  destinationWalletAcc: 'Destination Account No',
  destinationWalletName: 'Destination Account Name',
  walletCd: 'Wallet Code',
  amount: 'Amount',
  withdrawalStatus: 'Status',
  callbackStatus: 'Result/ Response Status',
  withdrawalFee: 'Withdrawal Fee',
  approvalRejectionRemark: 'Approval/ Rejection Remark',
  merchantTxnId: 'Merchant Transaction ID',
  reference: 'Reference',
  approvedBy: 'Approved By',
  completedDT: 'Completed DateTime',
  matchStatus: 'Match Status',
}

function escapeCsvValue(value: string): string {
  // If the value contains a comma or line breaks, wrap it in double quotes and escape existing double quotes and line breaks
  if (value.includes(',') || value.includes('\n') || value.includes('\r')) {
    return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
  }
  return value
}

function generateFile(formData: any) {
  let dataset = formData.map((obj: any) => {
    const updatedObj = {...obj}
    delete updatedObj.merchantTimestamp
    // delete updatedObj.reqId
    return updatedObj
  })

  dataset = modifyDT(dataset, 'Completed DateTime')
  dataset = modifyDT(dataset, 'Withdrawal DateTime')

  const header = Object.keys(dataset[0]).join(',')
  const rows = dataset.map((transaction: any) =>
    Object.values(transaction)
      .map((value: any) => escapeCsvValue(value.toString()))
      .join(',')
  )

  const csvContent = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvContent], {type: 'text/csv'})

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `withdrawal_${getCurrentDateTime()}.csv`
  a.click()

  URL.revokeObjectURL(url)
}
export default function Withdrawal_Export_CSV(filterValues: any, setDownloadLoading: any): void {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  let searchCustomer = filterValues.searchCustomer.trim()
  let searchTransaction = filterValues.searchTransaction
  let merchantCd = filterValues.merchantCd.trim()
  let walletCd: string = filterValues.walletCd.trim()
  let walletAccNo: string = filterValues.walletAccNo
  let withdrawalStatus = filterValues.withdrawalStatus.trim()
  let dateTimeFrom = filterValues.dateTimeFrom.trim()
  let dateTimeTo = filterValues.dateTimeTo.trim()
  let callbackStatus = filterValues.callbackStatus.trim()
  let matchStatus = filterValues.matchStatus
  let timeZone = filterValues.timeZone

  setDownloadLoading(true)

  axios
    .post(
      `${SISPAY_API}/cash/withdrawalrequest/list`,
      {
        request: {
          action: 'download',
          session: session,
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
          // page: currentPage,
          //Filter
          ...(searchCustomer && {byCustomer: searchCustomer}),
          ...(searchTransaction && {byTransaction: searchTransaction}),
          ...(withdrawalStatus && {byWDStatus: withdrawalStatus}),
          ...(merchantCd && {byMerchantCd: merchantCd}),
          ...(walletCd && {byWalletCd: walletCd}),
          ...(walletAccNo && {byWalletAccNo: walletAccNo}),
          ...(callbackStatus && {byCallbackStatus: callbackStatus}),
          ...(matchStatus && {byMatchStatus: matchStatus}),
          ...(timeZone && {timezone: timeZone}),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = response.data.result.depositRequestList

      formData = formData.map((item: any) => {
        if (item.walletCd === 'BKASH') {
          item.walletCd = 'TapNGo'
        }
        return item
      })

      generateFile(modifyKeys(formData, keyMap))

      setDownloadLoading(false)
    })
    .catch(function (error) {})
}
