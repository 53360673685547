import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth'
import {useRef, useState} from 'react'
import getTimestamp from '../../components/GetTimestamp'
import {usePendingTransactionStore} from './PendingTransaction_Store'
import {useTransactionStore} from './Transaction_Store'

export default function Transaction_Reject_Modal(props: any) {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const navigate = useNavigate()
  const {logout} = useAuth()

  //Props
  let transData = props.transData

  //States
  const [loading, setLoading] = useState(false)

  //Refs
  const approvalRemarkRef = useRef<any>('')

  //Zustand Store
  const setPendingGetData = usePendingTransactionStore((state: any) => state.setGetData)
  const setGetData = useTransactionStore((state: any) => state.setGetData)

  function refreshPage() {
    const currentPath = window.location.pathname

    if (currentPath == '/transaction/list') {
      setGetData(true)
    } else if (currentPath == '/transaction/pending') {
      setPendingGetData(true)
    }
  }

  function rejectTransaction() {
    axios
      .post(
        `${SISPAY_API}/transaction/reject`,
        {
          request: {
            transId: transData.id,
            remark: approvalRemarkRef.current.value,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          setLoading(false)
          refreshPage()
          alert(response.data.message)
        } else {
          setLoading(false)
          refreshPage()
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div>
      <div
        className='modal fade'
        id='transRejectModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                REJECT Transaction
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-4'>ID</div>
                <div className='col-8 d-flex align-items-center'>{transData.id}</div>
              </div>
              <div className='separator separator-dashed border-secondary my-2'></div>
              <div className='row'>
                <div className='col-4'>Customer Code</div>
                <div className='col-8 d-flex align-items-center'>{transData.customerCd}</div>
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4'>Wallet Code</div>
                <div className='col-8 d-flex align-items-center'>{transData.walletCd}</div>
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4'>Amount</div>
                <div className='col-8 d-flex align-items-center'>{transData.amount}</div>
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4 d-flex align-items-center'>Remark</div>{' '}
                <div className='col-8 d-flex align-items-center justify-content-center'>
                  <input
                    type='text'
                    className='form-control col-4'
                    placeholder=''
                    ref={approvalRemarkRef}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  rejectTransaction()
                }}
              >
                REJECT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
