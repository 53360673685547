import axios from 'axios'
import {usePendingWithdrawalStore} from '../Withdrawal-Pending-Page/Withdrawal-Pending-Store'
import {useWithdrawalStore} from '../Withdrawal-Page/Withdrawal_Store'

export function EmulatorUpdateWithdrawalModal(props: any) {
  let withdrawalFilterSubmit = props.withdrawalFilterSubmit
  let selectedRow = props.selectedRow
  const API_URL = String(process.env.REACT_APP_SISPAY_API)

  //Zustand Store
  const setPendingGetData = usePendingWithdrawalStore((state: any) => state.setGetData)
  const setGetData = useWithdrawalStore((state: any) => state.setGetData)

  // Get the current URL
  var currentUrl = window.location.href

  function verifyCurrentUrl(currentUrl: string) {
    if (currentUrl.includes('withdrawal/list')) {
      setGetData(true)
    } else if (currentUrl.includes('/withdrawal/pending')) {
      setPendingGetData(true)
    } else {
      console.log('Current URL does not match the specified patterns')
    }
  }

  function reassignWithdrawal() {
    axios
      .post(API_URL + '/emulator/update/withdrawal', {
        request: {
          walletAccNo: selectedRow.walletAccNo,
          walletCd: selectedRow.walletCd,
          withdrawalTxnId: selectedRow.merchantTxnId,
          status: 'FAILED',
          reassign: 'true',
          remark: '',
        },
      })
      .then((response) => {
        withdrawalFilterSubmit.current.click()
        verifyCurrentUrl(currentUrl)
        alert(response.data.message)
      })
      .catch((error) => {
        verifyCurrentUrl(currentUrl)
        alert(error)
      })
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_emulator_update_withdrawal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Do you want to reassign withdrawal ID: {selectedRow.id}
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col'>Wallet Account Number</div>
              <div className='col fw-bold'>{selectedRow.walletAccNo}</div>
            </div>
            <div className='row'>
              <div className='col'>Wallet Code</div>
              <div className='col fw-bold'>{selectedRow.walletCd}</div>
            </div>
            <div className='row'>
              <div className='col'>Withdrawal TxnID</div>
              <div className='col fw-bold'>{selectedRow.merchantTxnId}</div>
            </div>
            <div className='row'>
              <div className='col'>Status</div>
              <div className='col fw-bold'>{selectedRow.withdrawalStatus}</div>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              className='btn btn-warning'
              data-bs-dismiss='modal'
              onClick={reassignWithdrawal}
            >
              Reassign
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
