import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {sortByDate} from '../../../components/SortByDate'
import {useAuth} from '../../../auth/core/Auth'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {sortByName} from '../../../components/SortByName'
import {useDetailedReportingStore} from './DetailedReporting_Store'
import {UniqueWalletAccNo} from '../../../components/UniqueWalletAccNo'
import Modal from '../../../components/Modal'

export function Detailed_Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let currentPage = props.currentPage
  let setCurrentPage = props.setCurrentPage
  let setTotalFormData = props.setTotalFormData
  let setFilterValues = props.setFilterValues

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState(Object)
  const [walletAccNoList, setWalletAccNoList] = useState([])

  //Refs
  const selectedMerchantCd = useRef<any>(null)
  const selectedWalletsRef = useRef<any>(null)

  //Zustand Store
  const filterOptions = useDetailedReportingStore((state: any) => state.filterOptions)
  const getData = useDetailedReportingStore((state: any) => state.getData)
  const setFilterOptions = useDetailedReportingStore((state: any) => state.setFilterOptions)
  const setGetData = useDetailedReportingStore((state: any) => state.setGetData)

  const handleWalletsChange = (selected: any) => {
    if (selected !== '') {
      setFilterOptions({
        ...filterOptions,
        wallets: selected.map((option: any) => option.value).join(','),
      })
    } else {
      setFilterOptions({...filterOptions, matchStatus: ''})
    }
  }

  const initialValues = {
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
    timeZone: filterOptions.timeZone ? String(filterOptions.timeZone) : 'GMT+8',
    wallets: filterOptions.wallets ? String(filterOptions.wallets) : '',
    basedOn: filterOptions.basedOn ? String(filterOptions.basedOn) : 'transCompleted',
    transType: filterOptions.transType ? String(filterOptions.transType) : 'ALL',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let merchantCd = values.merchantCd.trim()
      let timeZone = values.timeZone.trim()
      let basedOn = values.basedOn.trim()
      let transType = values.transType.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let wallets = filterOptions.wallets

      values.wallets = wallets

      setFilterValues(values)
      setFilterOptions(values)

      let submissionData = {}

      if (values.merchantCd !== '') {
        submissionData = {
          session: session,
          merchantCd: merchantCd,
          ...(wallets && {accountNos: wallets}),
          timezone: timeZone,
          basedOn: basedOn,
          transType: transType,
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
          //Pagination
          noOfRec: 100,
          offSet: 0,
        }
      } else {
        submissionData = {
          session: session,
          timezone: timeZone,
          ...(wallets && {accountNos: wallets}),
          basedOn: basedOn,
          transType: transType,
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
          //Pagination
          noOfRec: 100,
          offSet: 0,
        }
      }

      axios
        .post(
          `${SISPAY_API}/merchant/admin/detailedreport`,
          {
            request: submissionData,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            setTotalFormData(response.data.result.totalRecords)
            setFormData(sortByDate(response.data.result.merchantDetailedReport, 'date'))
            setDisabled(false)
            setLoading(false)
            if (currentPage !== 0) {
              setCurrentPage(1)
            }
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false && currentPage == 0) return

    if (getData == true) {
      setCurrentPage(1)
    }

    setDisabled(true)
    setLoading(true)
    let merchantCd = formik.values.merchantCd.trim()
    let timeZone = formik.values.timeZone.trim()
    let basedOn = formik.values.basedOn.trim()
    let transType = formik.values.transType.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()
    let wallets = filterOptions.wallets

    formik.values.wallets = wallets

    let submissionData = {}

    if (formik.values.merchantCd !== '') {
      submissionData = {
        session: session,
        merchantCd: merchantCd,
        timezone: timeZone,
        basedOn: basedOn,
        ...(wallets && {accountNos: wallets}),
        transType: transType,
        fromDate: dateTimeFrom,
        toDate: dateTimeTo,
        //Pagination
        noOfRec: 100,
        offSet: (currentPage - 1) * 100,
      }
    } else {
      submissionData = {
        session: session,
        timezone: timeZone,
        basedOn: basedOn,
        ...(wallets && {accountNos: wallets}),
        transType: transType,
        fromDate: dateTimeFrom,
        toDate: dateTimeTo,
        //Pagination
        noOfRec: 100,
        offSet: (currentPage - 1) * 100,
      }
    }

    axios
      .post(
        `${SISPAY_API}/merchant/admin/detailedreport`,
        {
          request: submissionData,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          setTotalFormData(response.data.result.totalRecords)
          setFormData(sortByDate(response.data.result.merchantDetailedReport, 'date'))
          setGetData(false)
          setDisabled(false)
          setLoading(false)
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }, [currentPage, getData])

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )

          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function retrieveWalletAccNo(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let walletAccNoList = UniqueWalletAccNo(result)

          setWalletAccNoList(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    retrieveData(SISPAY_API, '/merchant/list', 'merchant')
    retrieveWalletAccNo(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  return (
    <>
      <div className='card card-custom card-flush mb-5'>
        <div className='menu d-flex flex-column'>
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-2 py-1'>
            <div className='mb-10'>
              <form onSubmit={formik.handleSubmit} className='m-5'>
                {' '}
                <div className='fv-row mb-5'>
                  {' '}
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>

                        <div>
                          <Select
                            ref={selectedMerchantCd}
                            className='basic-single'
                            classNamePrefix='select'
                            options={merchantCdList}
                            styles={customStyles}
                            name='merchantCd'
                            isClearable={true}
                            value={
                              formik.values.merchantCd
                                ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                                : null
                            }
                            onChange={(e: any) =>
                              e !== null
                                ? formik.setFieldValue('merchantCd', e.value)
                                : formik.setFieldValue('merchantCd', '')
                            }
                          />
                        </div>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Timezone</label>
                        <select
                          {...formik.getFieldProps('timeZone')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value='GMT+6'>GMT+6</option>
                          <option value='GMT+7'>GMT+7</option>
                          <option value='GMT+8'>GMT+8</option>
                        </select>{' '}
                      </div>{' '}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Based On</label>
                        <select
                          {...formik.getFieldProps('basedOn')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          {/* <option value='transCreated'>Transaction Created</option> */}
                          <option value='transCompleted'>Transaction Completed</option>
                          <option value='transCreated'>Transaction Created</option>
                        </select>{' '}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Report Type</label>
                        <select
                          {...formik.getFieldProps('transType')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value='ALL'>All</option>
                          <option value='WITHDRAWAL'>Withdrawal</option>
                          <option value='DEPOSIT'>Deposit</option>
                        </select>{' '}
                      </div>{' '}
                    </div>
                  </div>
                  <div className='col'></div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group DateTime From */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Wallet</label>
                      <Select
                        ref={selectedWalletsRef}
                        isMulti
                        styles={customStyles}
                        name='wallets'
                        options={walletAccNoList}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        value={
                          filterOptions.wallets
                            ? filterOptions.wallets
                                .split(',')
                                .map((item: any) => ({value: item, label: item}))
                            : ''
                        }
                        isClearable={true}
                        onChange={handleWalletsChange}
                      />
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime From */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>From</label>
                      <input
                        placeholder='Enter your start time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeFrom')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                          },
                          {
                            'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                          }
                        )}
                      />
                      {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime To */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>To</label>
                      <input
                        placeholder='Enter your end time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeTo')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                          },
                          {
                            'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                          }
                        )}
                      />
                      {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeTo}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        className='btn btn-lg btn-light w-100 mb-5'
                        disabled={disabled}
                        onClick={() => {
                          selectedMerchantCd.current.setValue('')
                          selectedWalletsRef.current.setValue('')
                          setCurrentPage(1)
                          formik.resetForm({
                            values: {
                              merchantCd: '',
                              wallets: '',
                              timeZone: 'GMT+8',
                              basedOn: 'transCompleted',
                              transType: 'ALL',
                              dateTimeFrom: getCurrentDateInput(),
                              dateTimeTo: getCurrentDateInput(),
                            },
                          })
                        }}
                      >
                        {<span className='indicator-label'>Reset</span>}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid || disabled}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {Modal(loading)}
    </>
  )
}
